/* Reset everything */
*,
*::before,
*::after,
*::placeholder {
  margin: 0;
  padding: 0;
  outline: 0;
  opacity: 1;
  border: none;
  font: inherit;
  color: inherit;
  background: transparent;
  box-sizing: border-box;
  list-style: none;
  line-height: 150%;
  text-decoration: none;
  text-align: inherit;
  text-shadow: inherit;
  border-collapse: collapse;
}

*::-moz-focus-outer,
*::-moz-focus-inner {
  border: 0;
}

/* Design system */
html {
  /* Margins are 12px, text lines are 24px, buttons/inputs are 36px */
  --size-line: 2px;
  --size-1: 6px;
  --size-2: 12px;
  --size-3: 18px;
  --size-4: 24px;
  --size-5: 30px;
  --size-6: 36px;
  --size-7: 42px;
  --size-8: 48px;
  --size-9: 54px;
  --size-10: 60px;
  --font-small: 14px;
  --font-normal: 16px;
  --font-large: 20px;
  --font-huge: 24px;
  --font-gargantuan: 32px;
  --font-colossal: 48px;
  /* Munsell */
  --grey-90: rgb(227, 227, 227);
  --grey-85: rgb(213, 213, 213);
  --grey-80: rgb(200, 200, 200);
  --grey-75: rgb(187, 187, 187);
  --grey-70: rgb(173, 173, 173);
  --grey-60: rgb(147, 147, 147);
  --grey-50: rgb(121, 121, 121);
  --grey-40: rgb(96, 96, 96);
  --grey-30: rgb(72, 72, 72);
  --grey-35: rgb(84, 84, 84);
  --grey-25: rgb(60, 60, 60);
  --grey-20: rgb(49, 49, 49);
  --grey-15: rgb(39, 39, 39);
  --grey-10: rgb(28, 28, 28);
  /* Munsell */
  --red-70: rgb(238, 149, 144);
  /* 5.0R 7.0/8.0 */
  --red-60: rgb(209, 123, 119);
  /* 5.0R 6.0/8.0 */
  --red-55: rgb(203, 105, 103);
  /* 5.0R 5.5/9.0 */
  --red-50: rgb(196, 87, 86);
  /* 5.0R 5.0/10.0 */
  --red-45: rgb(175, 78, 78);
  /* 5.0R 4.5/9.0 */
  --red-40: rgb(155, 69, 69);
  /* 5.0R 4.0/8.0 */
  --red-30: rgb(116, 51, 53);
  /* 5.0R 3.0/6.0 */
  --red-20: rgb(77, 37, 42);
  /* 5.0R 2.0/4.0 */
  --yellow-70: rgb(209, 168, 72);
  /* 2.5Y 7.0/8.0 */
  --yellow-60: rgb(182, 142, 45);
  /* 2.5Y 6.0/8.0 */
  --yellow-55: rgb(170, 128, 3);
  /* 2.5Y 5.5/9.0 */
  --yellow-50: rgb(157, 115, -37);
  /* 2.5Y 5.0/10.0 */
  --yellow-45: rgb(141, 103, -21);
  /* 2.5Y 4.5/9.0 */
  --yellow-40: rgb(125, 90, -14);
  /* 2.5Y 4.0/8.0 */
  --yellow-30: rgb(94, 67, 1);
  /* 2.5Y 3.0/6.0 */
  --yellow-20: rgb(64, 46, 7);
  /* 2.5Y 2.0/4.0 */
  --green-70: rgb(120, 190, 112);
  /* 10.0GY 7.0/8.0 */
  --green-60: rgb(92, 164, 88);
  /* 10.0GY 6.0/8.0 */
  --green-55: rgb(68, 152, 68);
  /* 10.0GY 5.5/9.0 */
  --green-50: rgb(41, 140, 48);
  /* 10.0GY 5.0/10.0 */
  --green-45: rgb(30, 125, 44);
  /* 10.0GY 4.5/9.0 */
  --green-40: rgb(34, 111, 41);
  /* 10.0GY 4.0/8.0 */
  --green-30: rgb(32, 82, 38);
  /* 10.0GY 3.0/6.0 */
  --green-20: rgb(27, 55, 32);
  /* 10.0GY 2.0/4.0 */
  --blue-70: rgb(135, 176, 229);
  /* 5.0PB 7.0/8.0 */
  --blue-60: rgb(111, 149, 202);
  /* 5.0PB 6.0/8.0 */
  --blue-55: rgb(89, 136, 196);
  /* 5.0PB 5.5/9.0 */
  --blue-50: rgb(67, 124, 189);
  /* 5.0PB 5.0/10.0 */
  --blue-45: rgb(60, 110, 170);
  /* 5.0PB 4.5/9.0 */
  --blue-40: rgb(55, 97, 150);
  /* 5.0PB 4.0/8.0 */
  --blue-30: rgb(42, 72, 114);
  /* 5.0PB 3.0/6.0 */
  --blue-20: rgb(33, 49, 75);
  /* 5.0PB 2.0/4.0 */
  /* Theme */
  --input-background: var(--grey-30);
  --input-background-focus: var(--grey-35);
  --input-background-active: var(--grey-40);
  --input-error: var(--red-70);
  --header-background: var(--grey-10);
  --page-background: var(--grey-15);
  --page-foreground: var(--grey-20);
  --page-text: var(--grey-90);
  --scroll-background: var(--grey-20);
  --scroll-foreground: var(--grey-50);
  --spinner-color: var(--grey-50);
}

/* Basics */
body {
  font-family: "Ubuntu", sans-serif;
  font-size: var(--font-normal);
  font-weight: 400;
  color: var(--page-text);
  text-shadow: 0 0 2px var(--page-background);
  background: var(--page-background);
}

/* Scrollbars */
* {
  scrollbar-width: thin;
  scrollbar-color: var(--scroll-foreground) var(--scroll-background);
}

*::-webkit-scrollbar {
  width: var(--size-1);
  height: var(--size-1);
}

*::-webkit-scrollbar-track {
  background-color: var(--scroll-background);
}

*::-webkit-scrollbar-thumb {
  background-color: var(--scroll-foreground);
}
.nyx-button {
  display: inline-block;
  padding: var(--size-1) var(--size-3);
  background: var(--input-background);
  border-radius: var(--size-1);
  cursor: pointer;
  font-weight: 500;
  text-align: center;
}
.nyx-button:hover, .nyx-button:focus-visible {
  background: var(--input-background-focus);
}
.nyx-button:active {
  background: var(--input-background-active);
}
.nyx-button--square {
  padding: 0;
  width: var(--input-size);
  height: var(--input-size);
  font-size: calc(var(--input-size) / 1.5);
  --input-size: var(--size-6);
}
.nyx-button--small {
  --input-size: var(--size-4);
}
.nyx-button--large {
  --input-size: var(--size-8);
}
.nyx-button--transparent {
  --input-background: transparent;
  --input-background-focus: hsla(0, 0%, 100%, 0.17);
  --input-background-active: hsla(0, 0%, 100%, 0.23);
}
.nyx-button--white {
  --input-background: var(--grey-80);
  --input-background-focus: var(--grey-85);
  --input-background-active: var(--grey-90);
  text-shadow: 0 0 2px var(--grey-90);
  color: var(--grey-10);
}
.nyx-button--red {
  --input-background: var(--red-45);
  --input-background-focus: var(--red-50);
  --input-background-active: var(--red-55);
}
.nyx-button--yellow {
  --input-background: var(--yellow-45);
  --input-background-focus: var(--yellow-50);
  --input-background-active: var(--yellow-55);
}
.nyx-button--green {
  --input-background: var(--green-45);
  --input-background-focus: var(--green-50);
  --input-background-active: var(--green-55);
}
.nyx-button--blue {
  --input-background: var(--blue-45);
  --input-background-focus: var(--blue-50);
  --input-background-active: var(--blue-55);
}
.nyx-center-layout {
  padding-top: var(--size-10);
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: "main";
}
.nyx-center-layout > header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: var(--size-10);
}
.nyx-center-layout > main {
  grid-area: main;
  margin: var(--size-4);
  padding: var(--size-4);
  background: var(--page-foreground);
  border-radius: var(--size-1);
  width: 100%;
  max-width: 480px;
  justify-self: center;
}
@media (max-width: 480px) {
  .nyx-center-layout > main {
    margin: 0;
    border-radius: 0;
  }
}
.nyx-checkbox {
  display: inline-block;
  vertical-align: bottom;
  appearance: none;
  width: var(--size-4);
  height: var(--size-4);
  border-radius: var(--size-1);
  background: var(--input-background);
  cursor: pointer;
}
.nyx-checkbox:hover, .nyx-checkbox:focus-visible {
  background: var(--input-background-focus);
}
.nyx-checkbox:active {
  background: var(--input-background-active);
}
.nyx-checkbox:checked::after {
  content: "";
  position: relative;
  display: block;
  top: 4px;
  left: 1px;
  border-right: 3px solid var(--page-text);
  border-bottom: 3px solid var(--page-text);
  width: 9px;
  height: 15px;
  transform: rotate(45deg);
  transform-origin: bottom right;
  pointer-events: none;
}
.nyx-checkbox + label {
  padding-left: var(--size-1);
}
.nyx-column {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: var(--size-2);
}
.nyx-column--align-start {
  align-items: flex-start;
}
.nyx-column--align-center {
  align-items: center;
}
.nyx-column--align-end {
  align-items: flex-end;
}
.nyx-column--spacing-small {
  gap: var(--size-1);
}
.nyx-column--spacing-large {
  gap: var(--size-4);
}
.nyx-column--wrap {
  flex-wrap: wrap;
}
.nyx-dialog {
  position: fixed;
  inset: 0;
  z-index: 100;
  overflow: auto;
  background: rgba(39, 39, 39, 0.8);
}
.nyx-dialog > * {
  box-shadow: 0 0 2px 2px var(--page-background);
  margin: var(--size-5) auto;
  padding: var(--size-4);
  background: var(--page-foreground);
  border-radius: var(--size-1);
  width: 480px;
}
@media (max-width: 480px) {
  .nyx-dialog > * {
    margin: var(--size-5) 0;
    border-radius: 0;
    width: 100%;
  }
}
.nyx-error {
  padding-top: var(--size-1);
  color: var(--input-error);
}
.nyx-header {
  display: grid;
  grid-template-columns: auto auto 1fr auto;
  grid-template-areas: "home menu . actions";
  align-items: center;
  gap: var(--size-2);
  padding: var(--size-2) var(--size-4);
  background: var(--header-background);
  position: relative;
  z-index: 10;
}
.nyx-header--home {
  grid-area: home;
}
.nyx-header--menu {
  grid-area: menu;
}
.nyx-header--actions {
  grid-area: actions;
}
.nyx-header--apps {
  background: var(--header-background);
  border-radius: var(--size-1);
  padding: var(--size-2);
  top: calc(var(--size-2) + 100%);
  left: var(--size-2);
  position: absolute;
}
.nyx-header--apps > *:not(:first-child) {
  margin-top: var(--size-2);
}
@media (max-width: 480px) {
  .nyx-header--apps {
    width: calc(100% - var(--size-4));
  }
}
.nyx-header--home, .nyx-header--app {
  display: block;
  font-size: var(--font-huge);
  font-weight: 500;
  cursor: pointer;
}
.nyx-header--home img, .nyx-header--app img {
  width: var(--size-6);
  height: var(--size-6);
  vertical-align: bottom;
  margin-right: var(--size-2);
  border-radius: var(--size-1);
  background: var(--grey-30);
}
.nyx-header--home:hover, .nyx-header--home:focus-visible, .nyx-header--app:hover, .nyx-header--app:focus-visible {
  text-decoration: underline;
  text-decoration-thickness: var(--size-line);
}
.nyx-h1 {
  font-size: var(--font-gargantuan);
}

.nyx-h2 {
  font-size: var(--font-huge);
}

.nyx-h3 {
  font-size: var(--font-large);
  font-weight: 500;
}
.nyx-help {
  padding-top: var(--size-1);
}
.nyx-input {
  width: 100%;
}
.nyx-label {
  display: block;
  padding-bottom: var(--size-1);
  font-weight: 500;
}
.nyx-link {
  color: var(--blue-70);
}
.nyx-link:hover, .nyx-link:focus-visible {
  text-decoration: underline;
  text-decoration-thickness: var(--size-line);
}
.nyx-link:active {
  color: var(--blue-60);
}
.nyx-loading-layout {
  width: 100vw;
  min-height: 100vh;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  align-items: center;
  justify-items: center;
}
.nyx-message {
  position: relative;
  padding-left: 1.5em;
}
.nyx-message::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0.25em;
  background: currentColor;
  width: 1em;
  height: 1em;
  border-radius: 50%;
}
.nyx-message--error {
  color: var(--red-70);
}
.nyx-message--warn {
  color: var(--yellow-70);
}
.nyx-message--info {
  color: var(--blue-70);
}
.nyx-message--success {
  color: var(--green-70);
}
.nyx-nav-layout {
  width: 100vw;
  min-height: 100vh;
  padding-top: var(--size-10);
  display: grid;
  grid-template-columns: 240px 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: "nav main";
}
@media (max-width: 480px) {
  .nyx-nav-layout {
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;
    grid-template-areas: "nav" "main";
  }
}
.nyx-nav-layout > header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: var(--size-10);
}
.nyx-nav-layout > nav {
  padding: var(--size-4);
  background: var(--page-background);
}
@media (min-width: 481px) {
  .nyx-nav-layout > nav {
    position: fixed;
    top: var(--size-10);
    left: 0;
    bottom: 0;
    width: 240px;
    overflow: auto;
  }
}
@media (max-width: 480px) {
  .nyx-nav-layout > nav {
    grid-area: nav;
  }
}
.nyx-nav-layout > main {
  grid-area: main;
  padding: var(--size-4);
  background: var(--page-foreground);
  overflow: auto;
}
.nyx-nav-link {
  display: block;
  padding: var(--size-1) var(--size-3);
  border-radius: var(--size-1);
  cursor: pointer;
  font-weight: 500;
}
.nyx-nav-link:hover, .nyx-nav-link:focus-visible {
  background: var(--grey-30);
}
.nyx-nav-link:active {
  background: var(--grey-35);
}
.nyx-nav-link--active {
  background: var(--grey-25);
}
.nyx-progress {
  display: block;
  width: 100%;
  background: var(--input-background);
  border-radius: var(--size-1);
  height: var(--size-4);
  overflow: hidden;
}
.nyx-progress::-webkit-progress-bar {
  background: transparent;
}
.nyx-progress::-webkit-progress-value {
  background: var(--bar-color);
}
.nyx-progress::-moz-progress-bar {
  background: var(--bar-color);
}
.nyx-progress {
  --bar-color: var(--grey-50);
}
.nyx-progress--white {
  --bar-color: var(--grey-80);
}
.nyx-progress--red {
  --bar-color: var(--red-45);
}
.nyx-progress--yellow {
  --bar-color: var(--yellow-45);
}
.nyx-progress--green {
  --bar-color: var(--green-45);
}
.nyx-progress--blue {
  --bar-color: var(--blue-45);
}
.nyx-radio {
  display: inline-block;
  vertical-align: bottom;
  appearance: none;
  width: var(--size-4);
  height: var(--size-4);
  border-radius: 50%;
  background: var(--input-background);
  cursor: pointer;
}
.nyx-radio:hover, .nyx-radio:focus-visible {
  background: var(--input-background-focus);
}
.nyx-radio:active {
  background: var(--input-background-active);
}
.nyx-radio:checked::after {
  content: "";
  position: relative;
  display: block;
  top: var(--size-1);
  left: var(--size-1);
  width: var(--size-2);
  height: var(--size-2);
  border-radius: 50%;
  border: 6px solid var(--page-text);
  pointer-events: none;
}
.nyx-radio + label {
  padding-left: var(--size-1);
}
.nyx-radio-group > *:not(:first-child) {
  margin-top: var(--size-2);
}
.nyx-row {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  gap: var(--size-2);
}
.nyx-row--align-start {
  align-items: flex-start;
}
.nyx-row--align-center {
  align-items: center;
}
.nyx-row--align-end {
  align-items: flex-end;
}
.nyx-row--spacing-small {
  gap: var(--size-1);
}
.nyx-row--spacing-large {
  gap: var(--size-4);
}
.nyx-row--wrap {
  flex-wrap: wrap;
}
.nyx-section {
  background: var(--page-foreground);
  border-radius: var(--size-1);
  padding: var(--size-4);
}
.nyx-spinner {
  width: var(--size-4);
  height: var(--size-4);
  border: 3px solid var(--spinner-color);
  border-left-color: transparent;
  border-bottom-color: transparent;
  border-radius: 50%;
  animation: nyx-spinner 1s linear infinite;
}
.nyx-spinner--large {
  width: var(--size-6);
  height: var(--size-6);
}

@keyframes nyx-spinner {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(1turn);
  }
}
.nyx-table th {
  font-weight: 500;
  border-bottom: var(--size-line) solid var(--grey-70);
  padding: var(--size-1) var(--size-1);
}
.nyx-table td {
  border-bottom: var(--size-line) solid var(--grey-50);
  padding: var(--size-1) var(--size-1);
}
.nyx-table th:first-child,
.nyx-table td:first-child {
  padding-left: var(--size-2);
}
.nyx-table th:last-child,
.nyx-table td:last-child {
  padding-right: var(--size-2);
}
.nyx-textbox {
  display: block;
  width: 100%;
  padding: var(--size-1) var(--size-2);
  border-radius: var(--size-1);
  background: var(--input-background);
  font-weight: 500;
}
.nyx-textbox:hover, .nyx-textbox:focus-visible {
  background: var(--input-background-focus);
}
.nyx-textbox--error {
  box-shadow: inset 0 calc(0px - var(--size-4) - var(--size-line)) 0 calc(0px - var(--size-4)) var(--input-error);
}
