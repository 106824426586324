.sound-controls {
  position: absolute;
  bottom: var(--size-2);
  left: var(--size-2);
  max-width: 33%;
  z-index: 1;

  .sound {
    background: var(--grey-20);
    border-radius: var(--size-1);
    padding: var(--size-1);

    display: flex;
    align-items: center;

    .icon {
      width: var(--size-4);
      height: var(--size-4);
      padding: 4px; /* TODO */
    }

    .name {
      flex-grow: 1;
      padding: 0 var(--size-1);

      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}
