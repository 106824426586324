.volume-control {
  position: fixed;
  z-index: 3;
  padding: var(--size-2) var(--size-4);
  font-size: var(--font-huge);
  font-weight: 500;
  transition: opacity 0.1s;

  &.hidden {
    opacity: 0;
  }

  .icon {
    margin-right: var(--size-2);
  }
}
