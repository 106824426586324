.visual-group {
  position: relative;
  width: 100%;

  .header {
    display: inline-block;

    .name {
      margin-left: var(--size-1);
      margin-right: var(--size-1);
      font-size: var(--font-large);
      font-weight: 500;
    }

    .nyx-button {
      transition: opacity 0.1s;
      opacity: 0.2;
    }

    &:hover,
    &:focus-within {
      .nyx-button {
        opacity: 1;
      }
    }
  }

  .visual-thumbnails:not(:empty) {
    display: flex;
    flex-wrap: wrap;
    margin: calc(var(--size-1) * -1);
    margin-top: var(--size-1);
  }

  .visual-thumbnail, .add-visual {
    border-radius: var(--size-1);
    margin: var(--size-1);
    width: 240px;
    height: 135px;
  }

  .drop-highlight {
    position: absolute;
    inset: calc(var(--size-1) * -1);
    border-radius: var(--size-1);
    background: var(--blue-40);
    opacity: 0.5;
    pointer-events: none;
  }
}
