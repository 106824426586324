.sounds {
  .header {
    display: inline-block;

    .name {
      margin-right: var(--size-1);
      font-size: var(--font-large);
      font-weight: 500;
    }

    .nyx-button {
      transition: opacity 0.1s;
      opacity: 0.2;
    }

    &:hover,
    &:focus-within {
      .nyx-button {
        opacity: 1;
      }
    }
  }
}
