.edit-layout {
  /* fullscreen */
  position: fixed;
  inset: 0;
  overflow: auto;

  /* layout */
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: auto 1fr auto;
  grid-template-areas:
    "header header"
    "visuals sounds"
    "visuals preview";

  > header {
    grid-area: header;
    background: var(--grey-10);
  }

  > .visuals {
    grid-area: visuals;
    background: var(--grey-20);
    padding: var(--size-4);
    padding-top: var(--size-2);
    overflow: auto;
  }

  > .sounds {
    grid-area: sounds;
    background: var(--grey-15);
    padding: var(--size-4);
    padding-top: var(--size-2);
    overflow: auto;
  }

  > .preview {
    grid-area: preview;
    background: var(--grey-10);
    padding: var(--size-4);
    padding-top: var(--size-2);
    overflow: auto;
  }
}
