.ambiance-link {
  padding: var(--size-1);
  background: var(--input-background);
  border-radius: var(--size-1);
  display: flex;
  font-weight: 500;

  /* Revert link styles */
  color: unset !important;
  text-decoration: unset !important;

  &:hover,
  &:focus-visible {
    background: var(--input-background-focus);
    color: unset;
  }

  &:active {
    background: var(--input-background-active);
  }

  & > * {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    padding: 0 var(--size-2);
    flex-grow: 0;
    flex-shrink: 0;
  }

  & > *:first-child {
    flex-grow: 1;
    flex-shrink: 1;
  }

  & .icon {
    margin-right: var(--size-2);
  }
}
