.add-visual {
  position: relative;
  overflow: hidden;
  cursor: pointer;
  border: 2px solid var(--grey-30);
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--grey-50);

  &:hover,
  &:focus-visible {
    border: 2px solid var(--grey-40);
    color: var(--grey-80)
  }
}
