.sound-group {
  position: relative;
  width: 100%;

  background: var(--grey-20);
  border-radius: var(--size-1);

  .header {
    display: flex;
    align-items: center;
    padding: var(--size-1);

    .name {
      font-size: var(--font-large);
      font-weight: 500;
      flex-grow: 1;
      padding: 0 var(--size-1);
      line-height: var(--size-4);
      cursor: pointer;

      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;

      &:hover,
      &:focus-visible {
        background: var(--grey-30);
        border-radius: var(--size-1);
      }
    }

    .nyx-button {
      flex-shrink: 0;
      transition: opacity 0.1s;
      opacity: 0.2;
    }

    &:hover,
    &:focus-within {
      .nyx-button {
        opacity: 1;
      }
    }
  }

  .body {
    background: var(--grey-15);
    border-radius: var(--size-1);
    padding: var(--size-1);
    margin: 0 2px 2px 2px;
  }

  .sound {
    display: flex;
    align-items: center;

    .icon {
      opacity: 0.5;
      width: var(--size-4);
      height: var(--size-4);
      padding: 4px; /* TODO */
    }

    .name {
      flex-grow: 1;
      padding: 0 var(--size-1);

      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .nyx-button {
      flex-shrink: 0;
      transition: opacity 0.1s;
      opacity: 0.2;
    }

    &:hover,
    &:focus-within {
      .icon,
      .nyx-button {
        opacity: 1;
      }
    }
  }

  .sound.add {
    width: 100%;
    cursor: pointer;

    &:hover,
    &:focus-visible {
      background: var(--grey-30);
      border-radius: var(--size-1);
    }
  }
}
