*, :before, :after, ::placeholder {
  opacity: 1;
  font: inherit;
  color: inherit;
  box-sizing: border-box;
  text-align: inherit;
  text-shadow: inherit;
  border-collapse: collapse;
  background: none;
  border: none;
  outline: 0;
  margin: 0;
  padding: 0;
  line-height: 150%;
  text-decoration: none;
  list-style: none;
}

::-moz-focus-outer {
  border: 0;
}

::-moz-focus-inner {
  border: 0;
}

html {
  --size-line: 2px;
  --size-1: 6px;
  --size-2: 12px;
  --size-3: 18px;
  --size-4: 24px;
  --size-5: 30px;
  --size-6: 36px;
  --size-7: 42px;
  --size-8: 48px;
  --size-9: 54px;
  --size-10: 60px;
  --font-small: 14px;
  --font-normal: 16px;
  --font-large: 20px;
  --font-huge: 24px;
  --font-gargantuan: 32px;
  --font-colossal: 48px;
  --grey-90: #e3e3e3;
  --grey-85: #d5d5d5;
  --grey-80: #c8c8c8;
  --grey-75: #bbb;
  --grey-70: #adadad;
  --grey-60: #939393;
  --grey-50: #797979;
  --grey-40: #606060;
  --grey-30: #484848;
  --grey-35: #545454;
  --grey-25: #3c3c3c;
  --grey-20: #313131;
  --grey-15: #272727;
  --grey-10: #1c1c1c;
  --red-70: #ee9590;
  --red-60: #d17b77;
  --red-55: #cb6967;
  --red-50: #c45756;
  --red-45: #af4e4e;
  --red-40: #9b4545;
  --red-30: #743335;
  --red-20: #4d252a;
  --yellow-70: #d1a848;
  --yellow-60: #b68e2d;
  --yellow-55: #aa8003;
  --yellow-50: #9d7300;
  --yellow-45: #8d6700;
  --yellow-40: #7d5a00;
  --yellow-30: #5e4301;
  --yellow-20: #402e07;
  --green-70: #78be70;
  --green-60: #5ca458;
  --green-55: #449844;
  --green-50: #298c30;
  --green-45: #1e7d2c;
  --green-40: #226f29;
  --green-30: #205226;
  --green-20: #1b3720;
  --blue-70: #87b0e5;
  --blue-60: #6f95ca;
  --blue-55: #5988c4;
  --blue-50: #437cbd;
  --blue-45: #3c6eaa;
  --blue-40: #376196;
  --blue-30: #2a4872;
  --blue-20: #21314b;
  --input-background: var(--grey-30);
  --input-background-focus: var(--grey-35);
  --input-background-active: var(--grey-40);
  --input-error: var(--red-70);
  --header-background: var(--grey-10);
  --page-background: var(--grey-15);
  --page-foreground: var(--grey-20);
  --page-text: var(--grey-90);
  --scroll-background: var(--grey-20);
  --scroll-foreground: var(--grey-50);
  --spinner-color: var(--grey-50);
}

body {
  font-family: Ubuntu, sans-serif;
  font-size: var(--font-normal);
  color: var(--page-text);
  text-shadow: 0 0 2px var(--page-background);
  background: var(--page-background);
  font-weight: 400;
}

* {
  scrollbar-width: thin;
  scrollbar-color: var(--scroll-foreground) var(--scroll-background);
}

::-webkit-scrollbar {
  width: var(--size-1);
  height: var(--size-1);
}

::-webkit-scrollbar-track {
  background-color: var(--scroll-background);
}

::-webkit-scrollbar-thumb {
  background-color: var(--scroll-foreground);
}

.nyx-button {
  padding: var(--size-1) var(--size-3);
  background: var(--input-background);
  border-radius: var(--size-1);
  cursor: pointer;
  text-align: center;
  font-weight: 500;
  display: inline-block;
}

.nyx-button:hover, .nyx-button:focus-visible {
  background: var(--input-background-focus);
}

.nyx-button:active {
  background: var(--input-background-active);
}

.nyx-button--square {
  width: var(--input-size);
  height: var(--input-size);
  font-size: calc(var(--input-size) / 1.5);
  --input-size: var(--size-6);
  padding: 0;
}

.nyx-button--small {
  --input-size: var(--size-4);
}

.nyx-button--large {
  --input-size: var(--size-8);
}

.nyx-button--transparent {
  --input-background: transparent;
  --input-background-focus: #ffffff2b;
  --input-background-active: #ffffff3b;
}

.nyx-button--white {
  --input-background: var(--grey-80);
  --input-background-focus: var(--grey-85);
  --input-background-active: var(--grey-90);
  text-shadow: 0 0 2px var(--grey-90);
  color: var(--grey-10);
}

.nyx-button--red {
  --input-background: var(--red-45);
  --input-background-focus: var(--red-50);
  --input-background-active: var(--red-55);
}

.nyx-button--yellow {
  --input-background: var(--yellow-45);
  --input-background-focus: var(--yellow-50);
  --input-background-active: var(--yellow-55);
}

.nyx-button--green {
  --input-background: var(--green-45);
  --input-background-focus: var(--green-50);
  --input-background-active: var(--green-55);
}

.nyx-button--blue {
  --input-background: var(--blue-45);
  --input-background-focus: var(--blue-50);
  --input-background-active: var(--blue-55);
}

.nyx-center-layout {
  padding-top: var(--size-10);
  grid-template: "main" 1fr
  / 1fr;
  display: grid;
}

.nyx-center-layout > header {
  height: var(--size-10);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.nyx-center-layout > main {
  margin: var(--size-4);
  padding: var(--size-4);
  background: var(--page-foreground);
  border-radius: var(--size-1);
  grid-area: main;
  justify-self: center;
  width: 100%;
  max-width: 480px;
}

@media (width <= 480px) {
  .nyx-center-layout > main {
    border-radius: 0;
    margin: 0;
  }
}

.nyx-checkbox {
  vertical-align: bottom;
  appearance: none;
  width: var(--size-4);
  height: var(--size-4);
  border-radius: var(--size-1);
  background: var(--input-background);
  cursor: pointer;
  display: inline-block;
}

.nyx-checkbox:hover, .nyx-checkbox:focus-visible {
  background: var(--input-background-focus);
}

.nyx-checkbox:active {
  background: var(--input-background-active);
}

.nyx-checkbox:checked:after {
  content: "";
  border-right: 3px solid var(--page-text);
  border-bottom: 3px solid var(--page-text);
  transform-origin: 100% 100%;
  pointer-events: none;
  width: 9px;
  height: 15px;
  display: block;
  position: relative;
  top: 4px;
  left: 1px;
  transform: rotate(45deg);
}

.nyx-checkbox + label {
  padding-left: var(--size-1);
}

.nyx-column {
  align-items: stretch;
  gap: var(--size-2);
  flex-direction: column;
  display: flex;
}

.nyx-column--align-start {
  align-items: flex-start;
}

.nyx-column--align-center {
  align-items: center;
}

.nyx-column--align-end {
  align-items: flex-end;
}

.nyx-column--spacing-small {
  gap: var(--size-1);
}

.nyx-column--spacing-large {
  gap: var(--size-4);
}

.nyx-column--wrap {
  flex-wrap: wrap;
}

.nyx-dialog {
  z-index: 100;
  background: #272727cc;
  position: fixed;
  inset: 0;
  overflow: auto;
}

.nyx-dialog > * {
  box-shadow: 0 0 2px 2px var(--page-background);
  margin: var(--size-5) auto;
  padding: var(--size-4);
  background: var(--page-foreground);
  border-radius: var(--size-1);
  width: 480px;
}

@media (width <= 480px) {
  .nyx-dialog > * {
    margin: var(--size-5) 0;
    border-radius: 0;
    width: 100%;
  }
}

.nyx-error {
  padding-top: var(--size-1);
  color: var(--input-error);
}

.nyx-header {
  align-items: center;
  gap: var(--size-2);
  padding: var(--size-2) var(--size-4);
  background: var(--header-background);
  z-index: 10;
  grid-template-columns: auto auto 1fr auto;
  grid-template-areas: "home menu . actions";
  display: grid;
  position: relative;
}

.nyx-header--home {
  grid-area: home;
}

.nyx-header--menu {
  grid-area: menu;
}

.nyx-header--actions {
  grid-area: actions;
}

.nyx-header--apps {
  background: var(--header-background);
  border-radius: var(--size-1);
  padding: var(--size-2);
  top: calc(var(--size-2)  + 100%);
  left: var(--size-2);
  position: absolute;
}

.nyx-header--apps > :not(:first-child) {
  margin-top: var(--size-2);
}

@media (width <= 480px) {
  .nyx-header--apps {
    width: calc(100% - var(--size-4));
  }
}

.nyx-header--home, .nyx-header--app {
  font-size: var(--font-huge);
  cursor: pointer;
  font-weight: 500;
  display: block;
}

.nyx-header--home img, .nyx-header--app img {
  width: var(--size-6);
  height: var(--size-6);
  vertical-align: bottom;
  margin-right: var(--size-2);
  border-radius: var(--size-1);
  background: var(--grey-30);
}

.nyx-header--home:hover, .nyx-header--home:focus-visible, .nyx-header--app:hover, .nyx-header--app:focus-visible {
  text-decoration: underline;
  text-decoration-thickness: var(--size-line);
}

.nyx-h1 {
  font-size: var(--font-gargantuan);
}

.nyx-h2 {
  font-size: var(--font-huge);
}

.nyx-h3 {
  font-size: var(--font-large);
  font-weight: 500;
}

.nyx-help {
  padding-top: var(--size-1);
}

.nyx-input {
  width: 100%;
}

.nyx-label {
  padding-bottom: var(--size-1);
  font-weight: 500;
  display: block;
}

.nyx-link {
  color: var(--blue-70);
}

.nyx-link:hover, .nyx-link:focus-visible {
  text-decoration: underline;
  text-decoration-thickness: var(--size-line);
}

.nyx-link:active {
  color: var(--blue-60);
}

.nyx-loading-layout {
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;
  place-items: center;
  width: 100vw;
  min-height: 100vh;
  display: grid;
}

.nyx-message {
  padding-left: 1.5em;
  position: relative;
}

.nyx-message:before {
  content: "";
  background: currentColor;
  border-radius: 50%;
  width: 1em;
  height: 1em;
  position: absolute;
  top: .25em;
  left: 0;
}

.nyx-message--error {
  color: var(--red-70);
}

.nyx-message--warn {
  color: var(--yellow-70);
}

.nyx-message--info {
  color: var(--blue-70);
}

.nyx-message--success {
  color: var(--green-70);
}

.nyx-nav-layout {
  padding-top: var(--size-10);
  grid-template: "nav main" 1fr
  / 240px 1fr;
  width: 100vw;
  min-height: 100vh;
  display: grid;
}

@media (width <= 480px) {
  .nyx-nav-layout {
    grid-template: "nav"
                   "main" 1fr
                   / 1fr;
  }
}

.nyx-nav-layout > header {
  height: var(--size-10);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.nyx-nav-layout > nav {
  padding: var(--size-4);
  background: var(--page-background);
}

@media (width >= 481px) {
  .nyx-nav-layout > nav {
    top: var(--size-10);
    width: 240px;
    position: fixed;
    bottom: 0;
    left: 0;
    overflow: auto;
  }
}

@media (width <= 480px) {
  .nyx-nav-layout > nav {
    grid-area: nav;
  }
}

.nyx-nav-layout > main {
  padding: var(--size-4);
  background: var(--page-foreground);
  grid-area: main;
  overflow: auto;
}

.nyx-nav-link {
  padding: var(--size-1) var(--size-3);
  border-radius: var(--size-1);
  cursor: pointer;
  font-weight: 500;
  display: block;
}

.nyx-nav-link:hover, .nyx-nav-link:focus-visible {
  background: var(--grey-30);
}

.nyx-nav-link:active {
  background: var(--grey-35);
}

.nyx-nav-link--active {
  background: var(--grey-25);
}

.nyx-progress {
  background: var(--input-background);
  border-radius: var(--size-1);
  height: var(--size-4);
  width: 100%;
  display: block;
  overflow: hidden;
}

.nyx-progress::-webkit-progress-bar {
  background: none;
}

.nyx-progress::-webkit-progress-value {
  background: var(--bar-color);
}

.nyx-progress::-moz-progress-bar {
  background: var(--bar-color);
}

.nyx-progress {
  --bar-color: var(--grey-50);
}

.nyx-progress--white {
  --bar-color: var(--grey-80);
}

.nyx-progress--red {
  --bar-color: var(--red-45);
}

.nyx-progress--yellow {
  --bar-color: var(--yellow-45);
}

.nyx-progress--green {
  --bar-color: var(--green-45);
}

.nyx-progress--blue {
  --bar-color: var(--blue-45);
}

.nyx-radio {
  vertical-align: bottom;
  appearance: none;
  width: var(--size-4);
  height: var(--size-4);
  background: var(--input-background);
  cursor: pointer;
  border-radius: 50%;
  display: inline-block;
}

.nyx-radio:hover, .nyx-radio:focus-visible {
  background: var(--input-background-focus);
}

.nyx-radio:active {
  background: var(--input-background-active);
}

.nyx-radio:checked:after {
  content: "";
  top: var(--size-1);
  left: var(--size-1);
  width: var(--size-2);
  height: var(--size-2);
  border: 6px solid var(--page-text);
  pointer-events: none;
  border-radius: 50%;
  display: block;
  position: relative;
}

.nyx-radio + label {
  padding-left: var(--size-1);
}

.nyx-radio-group > :not(:first-child) {
  margin-top: var(--size-2);
}

.nyx-row {
  align-items: stretch;
  gap: var(--size-2);
  flex-direction: row;
  display: flex;
}

.nyx-row--align-start {
  align-items: flex-start;
}

.nyx-row--align-center {
  align-items: center;
}

.nyx-row--align-end {
  align-items: flex-end;
}

.nyx-row--spacing-small {
  gap: var(--size-1);
}

.nyx-row--spacing-large {
  gap: var(--size-4);
}

.nyx-row--wrap {
  flex-wrap: wrap;
}

.nyx-section {
  background: var(--page-foreground);
  border-radius: var(--size-1);
  padding: var(--size-4);
}

.nyx-spinner {
  width: var(--size-4);
  height: var(--size-4);
  border: 3px solid var(--spinner-color);
  border-bottom-color: #0000;
  border-left-color: #0000;
  border-radius: 50%;
  animation: 1s linear infinite nyx-spinner;
}

.nyx-spinner--large {
  width: var(--size-6);
  height: var(--size-6);
}

@keyframes nyx-spinner {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(1turn);
  }
}

.nyx-table th {
  border-bottom: var(--size-line) solid var(--grey-70);
  padding: var(--size-1) var(--size-1);
  font-weight: 500;
}

.nyx-table td {
  border-bottom: var(--size-line) solid var(--grey-50);
  padding: var(--size-1) var(--size-1);
}

.nyx-table th:first-child, .nyx-table td:first-child {
  padding-left: var(--size-2);
}

.nyx-table th:last-child, .nyx-table td:last-child {
  padding-right: var(--size-2);
}

.nyx-textbox {
  padding: var(--size-1) var(--size-2);
  border-radius: var(--size-1);
  background: var(--input-background);
  width: 100%;
  font-weight: 500;
  display: block;
}

.nyx-textbox:hover, .nyx-textbox:focus-visible {
  background: var(--input-background-focus);
}

.nyx-textbox--error {
  box-shadow: inset 0 calc(0px - var(--size-4)  - var(--size-line)) 0 calc(0px - var(--size-4)) var(--input-error);
}

@font-face {
  font-family: Ubuntu;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("ubuntu-cyrillic-ext-400-normal.woff2") format("woff2"), url("ubuntu-all-400-normal.woff") format("woff");
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: Ubuntu;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("ubuntu-cyrillic-400-normal.woff2") format("woff2"), url("ubuntu-all-400-normal.woff") format("woff");
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-family: Ubuntu;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("ubuntu-greek-ext-400-normal.woff2") format("woff2"), url("ubuntu-all-400-normal.woff") format("woff");
  unicode-range: U+1F??;
}

@font-face {
  font-family: Ubuntu;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("ubuntu-greek-400-normal.woff2") format("woff2"), url("ubuntu-all-400-normal.woff") format("woff");
  unicode-range: U+370-3FF;
}

@font-face {
  font-family: Ubuntu;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("ubuntu-latin-ext-400-normal.woff2") format("woff2"), url("ubuntu-all-400-normal.woff") format("woff");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Ubuntu;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("ubuntu-latin-400-normal.woff2") format("woff2"), url("ubuntu-all-400-normal.woff") format("woff");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Ubuntu;
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url("ubuntu-cyrillic-ext-500-normal.woff2") format("woff2"), url("ubuntu-all-500-normal.woff") format("woff");
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: Ubuntu;
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url("ubuntu-cyrillic-500-normal.woff2") format("woff2"), url("ubuntu-all-500-normal.woff") format("woff");
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-family: Ubuntu;
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url("ubuntu-greek-ext-500-normal.woff2") format("woff2"), url("ubuntu-all-500-normal.woff") format("woff");
  unicode-range: U+1F??;
}

@font-face {
  font-family: Ubuntu;
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url("ubuntu-greek-500-normal.woff2") format("woff2"), url("ubuntu-all-500-normal.woff") format("woff");
  unicode-range: U+370-3FF;
}

@font-face {
  font-family: Ubuntu;
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url("ubuntu-latin-ext-500-normal.woff2") format("woff2"), url("ubuntu-all-500-normal.woff") format("woff");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Ubuntu;
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url("ubuntu-latin-500-normal.woff2") format("woff2"), url("ubuntu-all-500-normal.woff") format("woff");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

.ambiance-link {
  padding: var(--size-1);
  background: var(--input-background);
  border-radius: var(--size-1);
  font-weight: 500;
  display: flex;
  color: unset !important;
  -webkit-text-decoration: unset !important;
  text-decoration: unset !important;
}

.ambiance-link:hover, .ambiance-link:focus-visible {
  background: var(--input-background-focus);
  color: unset;
}

.ambiance-link:active {
  background: var(--input-background-active);
}

.ambiance-link > * {
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 0 var(--size-2);
  flex-grow: 0;
  flex-shrink: 0;
  overflow: hidden;
}

.ambiance-link > :first-child {
  flex-grow: 1;
  flex-shrink: 1;
}

.ambiance-link .icon {
  margin-right: var(--size-2);
}

.edit-layout {
  grid-template: "header header"
                 "visuals sounds" 1fr
                 "visuals preview"
                 / 2fr 1fr;
  display: grid;
  position: fixed;
  inset: 0;
  overflow: auto;
}

.edit-layout > header {
  background: var(--grey-10);
  grid-area: header;
}

.edit-layout > .visuals {
  background: var(--grey-20);
  padding: var(--size-4);
  padding-top: var(--size-2);
  grid-area: visuals;
  overflow: auto;
}

.edit-layout > .sounds {
  background: var(--grey-15);
  padding: var(--size-4);
  padding-top: var(--size-2);
  grid-area: sounds;
  overflow: auto;
}

.edit-layout > .preview {
  background: var(--grey-10);
  padding: var(--size-4);
  padding-top: var(--size-2);
  grid-area: preview;
  overflow: auto;
}

.visual-thumbnail {
  cursor: pointer;
  background: #000;
  position: relative;
  overflow: hidden;
}

.visual-thumbnail:hover, .visual-thumbnail:focus-visible {
  outline: 2px solid var(--grey-30);
}

.visual-thumbnail .name {
  text-align: center;
  text-shadow: 1px 1px 1px #000, -1px -1px 1px #000, -1px 1px 1px #000, 1px -1px 1px #000;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-left: var(--size-1);
  padding-right: var(--size-1);
  opacity: .8;
  transition: opacity .1s;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
}

.visual-thumbnail .edit {
  top: var(--size-1);
  left: var(--size-1);
}

.visual-thumbnail .delete {
  top: var(--size-1);
  right: var(--size-1);
}

.visual-thumbnail .edit, .visual-thumbnail .delete {
  opacity: 0;
  transition: opacity .1s;
  position: absolute;
}

.visual-thumbnail:hover .name, .visual-thumbnail:hover .edit, .visual-thumbnail:hover .delete, .visual-thumbnail:focus-within .name, .visual-thumbnail:focus-within .edit, .visual-thumbnail:focus-within .delete {
  opacity: 1;
}

.visual-group {
  width: 100%;
  position: relative;
}

.visual-group .header {
  display: inline-block;
}

.visual-group .header .name {
  margin-left: var(--size-1);
  margin-right: var(--size-1);
  font-size: var(--font-large);
  font-weight: 500;
}

.visual-group .header .nyx-button {
  opacity: .2;
  transition: opacity .1s;
}

.visual-group .header:hover .nyx-button, .visual-group .header:focus-within .nyx-button {
  opacity: 1;
}

.visual-group .visual-thumbnails:not(:empty) {
  margin: calc(var(--size-1) * -1);
  margin-top: var(--size-1);
  flex-wrap: wrap;
  display: flex;
}

.visual-group .visual-thumbnail, .visual-group .add-visual {
  border-radius: var(--size-1);
  margin: var(--size-1);
  width: 240px;
  height: 135px;
}

.visual-group .drop-highlight {
  inset: calc(var(--size-1) * -1);
  border-radius: var(--size-1);
  background: var(--blue-40);
  opacity: .5;
  pointer-events: none;
  position: absolute;
}

.add-visual {
  cursor: pointer;
  border: 2px solid var(--grey-30);
  color: var(--grey-50);
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
  overflow: hidden;
}

.add-visual:hover, .add-visual:focus-visible {
  border: 2px solid var(--grey-40);
  color: var(--grey-80);
}

.visuals .header {
  display: inline-block;
}

.visuals .header .name {
  margin-right: var(--size-1);
  font-size: var(--font-large);
  font-weight: 500;
}

.visuals .header .nyx-button {
  opacity: .2;
  transition: opacity .1s;
}

.visuals .header:hover .nyx-button, .visuals .header:focus-within .nyx-button {
  opacity: 1;
}

.sounds .header {
  display: inline-block;
}

.sounds .header .name {
  margin-right: var(--size-1);
  font-size: var(--font-large);
  font-weight: 500;
}

.sounds .header .nyx-button {
  opacity: .2;
  transition: opacity .1s;
}

.sounds .header:hover .nyx-button, .sounds .header:focus-within .nyx-button {
  opacity: 1;
}

.sound-group {
  background: var(--grey-20);
  border-radius: var(--size-1);
  width: 100%;
  position: relative;
}

.sound-group .header {
  padding: var(--size-1);
  align-items: center;
  display: flex;
}

.sound-group .header .name {
  font-size: var(--font-large);
  padding: 0 var(--size-1);
  font-weight: 500;
  line-height: var(--size-4);
  cursor: pointer;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex-grow: 1;
  overflow: hidden;
}

.sound-group .header .name:hover, .sound-group .header .name:focus-visible {
  background: var(--grey-30);
  border-radius: var(--size-1);
}

.sound-group .header .nyx-button {
  opacity: .2;
  flex-shrink: 0;
  transition: opacity .1s;
}

.sound-group .header:hover .nyx-button, .sound-group .header:focus-within .nyx-button {
  opacity: 1;
}

.sound-group .body {
  background: var(--grey-15);
  border-radius: var(--size-1);
  padding: var(--size-1);
  margin: 0 2px 2px;
}

.sound-group .sound {
  align-items: center;
  display: flex;
}

.sound-group .sound .icon {
  opacity: .5;
  width: var(--size-4);
  height: var(--size-4);
  padding: 4px;
}

.sound-group .sound .name {
  padding: 0 var(--size-1);
  white-space: nowrap;
  text-overflow: ellipsis;
  flex-grow: 1;
  overflow: hidden;
}

.sound-group .sound .nyx-button {
  opacity: .2;
  flex-shrink: 0;
  transition: opacity .1s;
}

.sound-group .sound:hover .icon, .sound-group .sound:hover .nyx-button, .sound-group .sound:focus-within .icon, .sound-group .sound:focus-within .nyx-button {
  opacity: 1;
}

.sound-group .sound.add {
  cursor: pointer;
  width: 100%;
}

.sound-group .sound.add:hover, .sound-group .sound.add:focus-visible {
  background: var(--grey-30);
  border-radius: var(--size-1);
}

.sound-controls {
  bottom: var(--size-2);
  left: var(--size-2);
  z-index: 1;
  max-width: 33%;
  position: absolute;
}

.sound-controls .sound {
  background: var(--grey-20);
  border-radius: var(--size-1);
  padding: var(--size-1);
  align-items: center;
  display: flex;
}

.sound-controls .sound .icon {
  width: var(--size-4);
  height: var(--size-4);
  padding: 4px;
}

.sound-controls .sound .name {
  padding: 0 var(--size-1);
  white-space: nowrap;
  text-overflow: ellipsis;
  flex-grow: 1;
  overflow: hidden;
}

.view-layout {
  background: #000;
  position: fixed;
  inset: 0;
  overflow: hidden;
}

.volume-control {
  z-index: 3;
  padding: var(--size-2) var(--size-4);
  font-size: var(--font-huge);
  font-weight: 500;
  transition: opacity .1s;
  position: fixed;
}

.volume-control.hidden {
  opacity: 0;
}

.volume-control .icon {
  margin-right: var(--size-2);
}

svg:not(:root).svg-inline--fa {
  overflow: visible;
}

.svg-inline--fa {
  font-size: inherit;
  vertical-align: -.125em;
  height: 1em;
  display: inline-block;
  overflow: visible;
}

.svg-inline--fa.fa-lg {
  vertical-align: -.225em;
}

.svg-inline--fa.fa-w-1 {
  width: .0625em;
}

.svg-inline--fa.fa-w-2 {
  width: .125em;
}

.svg-inline--fa.fa-w-3 {
  width: .1875em;
}

.svg-inline--fa.fa-w-4 {
  width: .25em;
}

.svg-inline--fa.fa-w-5 {
  width: .3125em;
}

.svg-inline--fa.fa-w-6 {
  width: .375em;
}

.svg-inline--fa.fa-w-7 {
  width: .4375em;
}

.svg-inline--fa.fa-w-8 {
  width: .5em;
}

.svg-inline--fa.fa-w-9 {
  width: .5625em;
}

.svg-inline--fa.fa-w-10 {
  width: .625em;
}

.svg-inline--fa.fa-w-11 {
  width: .6875em;
}

.svg-inline--fa.fa-w-12 {
  width: .75em;
}

.svg-inline--fa.fa-w-13 {
  width: .8125em;
}

.svg-inline--fa.fa-w-14 {
  width: .875em;
}

.svg-inline--fa.fa-w-15 {
  width: .9375em;
}

.svg-inline--fa.fa-w-16 {
  width: 1em;
}

.svg-inline--fa.fa-w-17 {
  width: 1.0625em;
}

.svg-inline--fa.fa-w-18 {
  width: 1.125em;
}

.svg-inline--fa.fa-w-19 {
  width: 1.1875em;
}

.svg-inline--fa.fa-w-20 {
  width: 1.25em;
}

.svg-inline--fa.fa-pull-left {
  width: auto;
  margin-right: .3em;
}

.svg-inline--fa.fa-pull-right {
  width: auto;
  margin-left: .3em;
}

.svg-inline--fa.fa-border {
  height: 1.5em;
}

.svg-inline--fa.fa-li {
  width: 2em;
}

.svg-inline--fa.fa-fw {
  width: 1.25em;
}

.fa-layers svg.svg-inline--fa {
  margin: auto;
  position: absolute;
  inset: 0;
}

.fa-layers {
  text-align: center;
  vertical-align: -.125em;
  width: 1em;
  height: 1em;
  display: inline-block;
  position: relative;
}

.fa-layers svg.svg-inline--fa {
  transform-origin: center;
}

.fa-layers-text, .fa-layers-counter {
  text-align: center;
  display: inline-block;
  position: absolute;
}

.fa-layers-text {
  transform-origin: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.fa-layers-counter {
  box-sizing: border-box;
  color: #fff;
  text-overflow: ellipsis;
  transform-origin: 100% 0;
  background-color: #ff253a;
  border-radius: 1em;
  min-width: 1.5em;
  max-width: 5em;
  height: 1.5em;
  padding: .25em;
  line-height: 1;
  top: 0;
  right: 0;
  overflow: hidden;
  transform: scale(.25);
}

.fa-layers-bottom-right {
  transform-origin: 100% 100%;
  top: auto;
  bottom: 0;
  right: 0;
  transform: scale(.25);
}

.fa-layers-bottom-left {
  transform-origin: 0 100%;
  inset: auto auto 0 0;
  transform: scale(.25);
}

.fa-layers-top-right {
  transform-origin: 100% 0;
  top: 0;
  right: 0;
  transform: scale(.25);
}

.fa-layers-top-left {
  transform-origin: 0 0;
  top: 0;
  left: 0;
  right: auto;
  transform: scale(.25);
}

.fa-lg {
  vertical-align: -.0667em;
  font-size: 1.33333em;
  line-height: .75em;
}

.fa-xs {
  font-size: .75em;
}

.fa-sm {
  font-size: .875em;
}

.fa-1x {
  font-size: 1em;
}

.fa-2x {
  font-size: 2em;
}

.fa-3x {
  font-size: 3em;
}

.fa-4x {
  font-size: 4em;
}

.fa-5x {
  font-size: 5em;
}

.fa-6x {
  font-size: 6em;
}

.fa-7x {
  font-size: 7em;
}

.fa-8x {
  font-size: 8em;
}

.fa-9x {
  font-size: 9em;
}

.fa-10x {
  font-size: 10em;
}

.fa-fw {
  text-align: center;
  width: 1.25em;
}

.fa-ul {
  margin-left: 2.5em;
  padding-left: 0;
  list-style-type: none;
}

.fa-ul > li {
  position: relative;
}

.fa-li {
  text-align: center;
  line-height: inherit;
  width: 2em;
  position: absolute;
  left: -2em;
}

.fa-border {
  border: .08em solid #eee;
  border-radius: .1em;
  padding: .2em .25em .15em;
}

.fa-pull-left {
  float: left;
}

.fa-pull-right {
  float: right;
}

.fa.fa-pull-left, .fas.fa-pull-left, .far.fa-pull-left, .fal.fa-pull-left, .fab.fa-pull-left {
  margin-right: .3em;
}

.fa.fa-pull-right, .fas.fa-pull-right, .far.fa-pull-right, .fal.fa-pull-right, .fab.fa-pull-right {
  margin-left: .3em;
}

.fa-spin {
  animation: 2s linear infinite fa-spin;
}

.fa-pulse {
  animation: 1s steps(8, end) infinite fa-spin;
}

@keyframes fa-spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.fa-rotate-90 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
  transform: rotate(90deg);
}

.fa-rotate-180 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2)";
  transform: rotate(180deg);
}

.fa-rotate-270 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)";
  transform: rotate(270deg);
}

.fa-flip-horizontal {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)";
  transform: scale(-1, 1);
}

.fa-flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
  transform: scale(1, -1);
}

.fa-flip-both, .fa-flip-horizontal.fa-flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
  transform: scale(-1);
}

:root .fa-rotate-90, :root .fa-rotate-180, :root .fa-rotate-270, :root .fa-flip-horizontal, :root .fa-flip-vertical, :root .fa-flip-both {
  filter: none;
}

.fa-stack {
  width: 2.5em;
  height: 2em;
  display: inline-block;
  position: relative;
}

.fa-stack-1x, .fa-stack-2x {
  margin: auto;
  position: absolute;
  inset: 0;
}

.svg-inline--fa.fa-stack-1x {
  width: 1.25em;
  height: 1em;
}

.svg-inline--fa.fa-stack-2x {
  width: 2.5em;
  height: 2em;
}

.fa-inverse {
  color: #fff;
}

.sr-only {
  clip: rect(0, 0, 0, 0);
  border: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  clip: auto;
  width: auto;
  height: auto;
  margin: 0;
  position: static;
  overflow: visible;
}

.svg-inline--fa .fa-primary {
  fill: var(--fa-primary-color, currentColor);
  opacity: 1;
  opacity: var(--fa-primary-opacity, 1);
}

.svg-inline--fa .fa-secondary {
  fill: var(--fa-secondary-color, currentColor);
  opacity: .4;
  opacity: var(--fa-secondary-opacity, .4);
}

.svg-inline--fa.fa-swap-opacity .fa-primary {
  opacity: .4;
  opacity: var(--fa-secondary-opacity, .4);
}

.svg-inline--fa.fa-swap-opacity .fa-secondary {
  opacity: 1;
  opacity: var(--fa-primary-opacity, 1);
}

.svg-inline--fa mask .fa-primary, .svg-inline--fa mask .fa-secondary {
  fill: #000;
}

.fad.fa-inverse {
  color: #fff;
}

/*# sourceMappingURL=index.css.map */
