.visual-thumbnail {
  position: relative;
  overflow: hidden;
  cursor: pointer;
  background: black;

  &:hover,
  &:focus-visible {
    outline: 2px solid var(--grey-30);
  }

  .name {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;

    text-align: center;
    text-shadow: 1px 1px 1px black, -1px -1px 1px black, -1px 1px 1px black,
      1px -1px 1px black;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    padding-left: var(--size-1);
    padding-right: var(--size-1);
    transition: opacity 0.1s;
    opacity: 0.8;
  }

  .edit {
    top: var(--size-1);
    left: var(--size-1);
  }

  .delete {
    top: var(--size-1);
    right: var(--size-1);
  }

  .edit,
  .delete {
    position: absolute;
    transition: opacity 0.1s;
    opacity: 0;
  }

  &:hover,
  &:focus-within {
    .name,
    .edit,
    .delete {
      opacity: 1;
    }
  }
}
